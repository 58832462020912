<template>
  <div id="guard-info" class="p-2">
    <div id="avatar" class="d-flex justify-content-center mt-1 mb-3">
      <b-avatar v-if="!update" :src="fileUrl(guardData.avatar, 'guard', true)" size="150px" rounded class="rounded-lg"/>
      <FileUpload v-if="update" :key="guardId" automatic :action="`${$global.server_url}/guard/${guardData._id?guardData._id:''}/file`" fileName="files" :files="guardData._id?guardData.files:[]" model="guard"
        @on-success="successUpload" @on-error="failUpload" @on-delete="deleteUpload" limit="1" class="mt-0" />
    </div>
    <div id="guard-info" class="h5">
      <template v-if="update"><!-- 更新和添加 -->
        <q-form @submit.prevent="updateGuard()" class="d-flex flex-column gap-3">
          <!-- name -->
          <q-input type="text" ref="nameRef" v-model="guardData.name" class="pb-0" filled :label="$t('user.name')" :loading="loading" autocomplete="off" clearable lazy-rules filled
            :rules="[ val=>val !== null && val !== undefined && val !== '' || $t('rule.required'), val=>val.length<=50 || 'Too long']">
            <template #prepend><fa-icon :icon="['fad','user']" fixed-width class="text-primary"/></template>
          </q-input>
          <!-- phone -->
          <q-input type="text" ref="phoneRef" v-model="guardData.phone" class="pb-0" filled label :label="$t('user.phone')" :loading="loading" clearable autocomplete="off" unmasked-value mask="+65 #### ####" lazy-rules
            :rules="[ val=>/^$|^[3|6|8|9][0-9]{7}$/.test(val) || $t('rule.sg_phone')]">
            <template #prepend><fa-icon :icon="['fad','mobile']" fixed-width class="text-primary"/></template>
          </q-input>
          <!-- email -->
          <q-input type="email" ref="emailRef" v-model="guardData.email" class="pb-0" filled label :label="$t('user.email')" :loading="loading" clearable autocomplete="off" lazy-rules
            :rules="[ val=>/^$|^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(val) || $t('rule.email')]">
            <template #prepend><fa-icon :icon="['fad','envelope']" fixed-width class="text-primary"/></template>
          </q-input>
          <!-- number -->
          <q-input type="text" ref="numberRef" v-model="guardData.number" class="pb-0" filled label :label="$t('security.id_number')" :loading="loading" clearable autocomplete="off"
            :rules="[ val=>val !== null && val !== undefined && val !== '' || $t('rule.required'), val=>val.length<=50 || 'Too long']">
            <template #prepend><fa-icon :icon="['fad','id-badge']" fixed-width class="text-primary"/></template>
          </q-input>
          <!-- shift -->
          <q-select type="text" v-model="guardData.shift" multiple class="pb-0" filled label :label="$t('user.shift')" :loading="loading" :options="shiftOptions">
            <template #prepend><fa-icon :icon="['fad','history']" fixed-width class="text-primary"/></template>
            <template #selected-item="scope">
              <q-chip dense removable @remove="scope.removeAtIndex(scope.index)" :tabindex="scope.tabindex" color="light-primary" text-color="primary">{{scope.opt}}</q-chip>
            </template>
          </q-select>
          <!-- rank -->
          <div class="d-flex gap-2">
            <q-select v-model="guardData.rank" outlined :options="['Supervisor', 'Manager', 'Guard']" :label="$t('user.rank')" class="flex-even">
              <template #prepend><fa-icon :icon="['fad','user-tie']" fixed-width class="text-primary"/></template>
            </q-select>
            <q-input type="text" v-model="guardData.employeeId" filled :label="$t('user.employee_id')" :loading="loading" clearable autocomplete="off" class="flex-even" />
          </div>
          <!-- IC -->
          <div class="d-flex gap-2">
            <q-select v-model="guardData.icType" outlined :options="['NRIC', 'FIN', 'Passport']" :label="$t('user.ic_type')" class="flex-even">
              <template #prepend><fa-icon :icon="['fad','address-card']" fixed-width class="text-primary"/></template>
            </q-select>
            <q-input type="text" v-model="guardData.ic" filled :label="$t('user.ic')" :loading="loading" clearable autocomplete="off" class="flex-even" />
          </div>
          <!-- tags -->
          <q-select v-model="guardData.tags" outlined :label="$t('tags')" new-value-mode="add-unique" multiple hide-dropdown-icon use-input >
            <template #selected-item="scope">
              <q-chip dense removable @remove="scope.removeAtIndex(scope.index)" :tabindex="scope.tabindex" color="light-primary" text-color="primary">{{scope.opt}}</q-chip>
            </template>
          </q-select>
          <!-- Button -->
          <div class="d-flex justify-content-between mt-2">
            <q-btn v-if="update" push color="primary" no-caps type="submit" class="text-capitalize rounded-lg">{{guardId?$t('update'):$t('action.add')}}</q-btn>
            <q-btn color="danger" push class="text-capitalize rounded-lg" @click.prevent.stop="$store.commit('guard/TOGGLE_SIDEBAR', false)">{{$t('cancel')}}</q-btn>
          </div>
        </q-form>
      </template>

      <template v-else><!-- 详情 -->
        <div class="d-flex flex-column gap-3">
          <!-- Name -->
          <div class="d-flex gap-2">
            <fa-icon :icon="['fad','user']" fixed-width class="text-primary text-size-150"/>
            <div>
              <label class="text-primary d-block">{{$t('user.name')}}</label>
              {{guardData.name}}
            </div>
          </div>
          <!-- phone -->
          <div v-if="guardData.phone" class="d-flex gap-2">
            <fa-icon :icon="['fad','mobile']" fixed-width class="text-primary text-size-150"/>
            <div>
              <label class="text-primary d-block">{{$t('user.phone')}}</label>
              {{guardData.phone}}
            </div>
          </div>
          <!-- email -->
          <div v-if="guardData.email" class="d-flex gap-2">
            <fa-icon :icon="['fad','envelope']" fixed-width class="text-primary text-size-150"/>
            <div>
              <label class="text-primary d-block">{{$t('user.email')}}</label>
              {{guardData.email}}
            </div>
          </div>
          <!-- number -->
          <div v-if="guardData.number" class="d-flex gap-2">
            <fa-icon :icon="['fad','id-badge']" fixed-width class="text-primary text-size-150"/>
            <div>
              <label class="text-primary d-block">{{$t('security.id_number')}}</label>
              {{guardData.number}}
            </div>
          </div>
          <!-- Rank -->
          <div v-if="guardData.rank || guardData.employeeId" class="d-flex gap-2">
            <fa-icon :icon="['fad','user-tie']" fixed-width class="text-primary text-size-150"/>
            <div>
              <label class="text-primary d-block">{{$t('user.rank')}}</label>
              {{guardData.rank}} <small>{{guardData.employeeId}}</small>
            </div>
          </div>
          <!-- Shift -->
          <div v-if="guardData.shift && guardData.shift.length>0" class="d-flex gap-2">
            <fa-icon :icon="['fad','history']" fixed-width class="text-primary text-size-150"/>
            <div>
              <label class="text-primary d-block">{{$t('user.shift')}}</label>
              <div class="d-flex gap-2">
                <q-chip dense v-if="guardData.shift.includes('day')" class="flex-nowrap"><q-avatar icon="light_mode" color="danger" text-color="white" />Day</q-chip>
                <q-chip dense v-if="guardData.shift.includes('night')" class="flex-nowrap"><q-avatar icon="dark_mode" color="primary" text-color="white" />Night</q-chip>
                <q-chip dense v-if="guardData.shift.includes('weekday')" color="primary" text-color="white" class="flex-nowrap">Weekday</q-chip>
                <q-chip dense v-if="guardData.shift.includes('weekend')" color="danger" text-color="white" class="flex-nowrap">Weekend</q-chip>
              </div>
            </div>
          </div>
          <!-- Tags -->
          <div v-if="guardData.tags && guardData.tags.length>0" class="d-flex gap-2">
            <fa-icon :icon="['fad','tag']" fixed-width class="text-primary text-size-150"/>
            <div>
              <label class="text-primary d-block">{{$t('tags')}}</label>
              <q-chip dense v-for="(tag,i) in guardData.tags" :key="i" color="primary" text-color="white" class="mr-50 px-50">{{tag}}</q-chip>
            </div>
          </div>
        </div>        
      </template>
    </div>
    
  </div>
</template>

<script>
import useGuard from '../useGuard'
import { BAvatar, BBadge } from 'bootstrap-vue'

import FileUpload from '@core/components/file-upload/FileUpload'
import FileGallery from '@core/components/file-gallery/FileGallery'
import 'vuesax/dist/vuesax.css'
import { fileUrl } from '@core/utils/filter'

export default {
  name: 'guard-info',
  props: {
    update:{
      type: Boolean,
      default: false
    },
  },
  components:{
    BAvatar, BBadge,
    FileUpload, FileGallery
  },
  setup(props, {emit}){
    const { 
      loading,
      nameRef, numberRef, phoneRef, emailRef,

      // Sidebar
      guardId,
      guardData,
      guardFiles,
      updateGuard,

      // File
      successUpload,
      failUpload,
      deleteUpload
    } = useGuard(props, emit)

    const shiftOptions = ['day', 'night', 'weekday','weekend']

    return {
      loading,
      fileUrl,
      nameRef, numberRef, phoneRef, emailRef,

      // Sidebar
      guardId,
      guardData,
      guardFiles,
      updateGuard,

      // File
      successUpload,
      failUpload,
      deleteUpload,

      // Others
      shiftOptions
    }
  }
}
</script>

<style lang="scss">
.q-select {
  div.q-field__native{
    column-gap: 0.5rem;
  }
}
.text-size-150 {
  font-size: 1.5rem
}
</style>
