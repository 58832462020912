import axios from '@axios'

export default {
  namespaced: true,
  state: {
    guardListToUpdate: false,
    isSidebarActive: false,
    guardId: '',
    guardData: {files:[]},
    guardFiles: []
  },
  getters: {},
  mutations: {
    SET_IF_GUARD_LIST_UPDTAE(state, val){
      state.guardListToUpdate = val
    },
    TOGGLE_SIDEBAR(state, val){ // Open/close
      state.isSidebarActive = val
    },
    RESET_GUARD(state, val){
      state.guardId = '',
      state.guardData = {files:[]},
      state.guardFiles = []
    },
    SET_GUARD_ID(state, val){
      state.guardId = val
    },
    SET_GUARD_DATA(state, data){
      state.guardData = data
      state.guardFiles = data.files
    }
  },
  actions: {
    fetchGuards({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios.get('/guard', { params: queryParams })
          .then(response => {
            resolve(response.data)
            commit('SET_IF_GUARD_LIST_UPDTAE', false)
          })
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    fetchGuard({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios.get(`/guard/${id}`)
          .then(response => {
            commit('SET_GUARD_DATA', response.data.guard)
            resolve(response.data)
          }).catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    updateGuard(ctx, {id, guard}) {
      return new Promise((resolve, reject) => {
        axios.post(`/guard/${id||''}`, { ...guard })
          .then(response => resolve(response.data))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    disableGuard(ctx, id){
      return new Promise((resolve, reject) => {
        axios.delete(`/guard/${id}`)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
    removeFile(ctx, {id, fileName}){
      let url =''
      if (id) { url = `/guard/${id}/file/${fileName.replaceAll('/','%2F')}` } else { url = `/guard/file/${fileName}` }
      return new Promise((resolve, reject) => {
        axios.delete(url)
          .then(response => resolve(response))
          .catch(error => {
            if (error.response && error.response.data) return reject (error.response.data)
            return reject(error)
          })
      })
    },
  },
}
