<template>
  <BSidebar id="guard-sidebar" right sidebar-class="sidebar-lg" shadow backdrop :width="sidebarWidth" header-class="p-0" bg-variant="white"
    :visible="isSidebarActive" @hidden="$store.commit('guard/TOGGLE_SIDEBAR', false)">
    <template #header="{ hide }"><!-- Header -->
      <div class="w-100 d-flex justify-content-between align-items-center content-sidebar-header px-2 py-2 bg-white border-bottom">
        <h4 class="mb-0 text-capitalize text-primary">
          {{update?(guardId?$t('update'):$t('action.add')):''}} {{$t('security.guard')}}
        </h4>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
      </div>
    </template>

    <template #default><!-- BODY -->
      <q-scroll-area class="h-100" delay='600'
        :thumb-style="{ right: '4px', borderRadius: '5px', backgroundColor: '#7367f0', width: '5px', opacity: 0.6 }"
        :bar-style="{right: '2px', borderRadius: '9px', backgroundColor: '#7367f0', width: '9px',background: 'dark', opacity: 0.2}">
        <GuardInfo :update="update?true:false" />
      </q-scroll-area>
    </template>
  </BSidebar>
</template>

<script>
import { ref } from '@vue/composition-api'
import { BSidebar } from 'bootstrap-vue'
import GuardInfo from './components/GuardInfo.vue'
import useGuard from './useGuard'
export default {
  name: 'guard-sidebar',
  components: {
    GuardInfo, BSidebar
  },
  props:{
    update: {
      type: Boolean,
      default: false
    },
  },
  setup(){
    // 根据 tab 调节宽度
    const sidebarWidth = ref('')
    const tab = ref('details')
    const { 
      isSidebarActive,
      guardId
    } = useGuard()

    return {
      tab,
      sidebarWidth,
      isSidebarActive,
      guardId
    }
  }
}
</script>

<style lang="scss">
html {
  overflow: hidden;
}
#clocking-sidebar{
  transition: all 500ms;
  
  //Hide scrollbar
  ::-webkit-scrollbar {
    display: none;
  }
  .b-sidebar-body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}
</style>